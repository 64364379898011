@import url('https://fonts.googleapis.com/css?family=Overpass:200,300,400,500,600,700&subset=latin-ext');

html {
	position: relative;
	min-height: 100%;
}

body {
	background: url(../img/backgrounds/rockywall.png);
	min-height: 100%;
	font-family: 'Overpass', sans-serif;

	a, ul, p, h1, h2, h3, h4, h5, h6 {
		margin: 0;
		padding: 0;
		color: #222;
		font-weight: 200;
	}

	p {
		font-size: 15px;
		line-height: 25px;
	}

	.section-heading {
		h2 {
			text-transform: uppercase;
		}

		p {
			text-transform: uppercase;
		}
	}

	.btn-default {
		border: 1px solid #222;
		border-radius: 0px;
		font-size: 14px;
		padding: 8px 20px;
		text-transform: uppercase;
		color: #222;
		transition: all 0.3s;
		background: none;

		&:hover, &:active, &:focus {
			background: #222;
			border-color: #222;
			color: #fff;
			outline: 0;
		}
	}

	.m-t-5 { margin-top: 5px; }
	.m-t-10 { margin-top: 10px; }
	.m-t-15 { margin-top: 15px; }
	.m-t-20 { margin-top: 20px; }
	.m-t-25 { margin-top: 25px; }
	.m-t-30 { margin-top: 30px; }
	.m-b-5 { margin-bottom: 5px; }
	.m-b-10 { margin-bottom: 10px; }
	.m-b-15 { margin-bottom: 15px; }
	.m-b-20 { margin-bottom: 20px; }
	.m-b-25 { margin-bottom: 25px; }
	.m-b-30 { margin-bottom: 30px; }
	.p-t-5 { padding-top: 5px; }
	.p-t-10 { padding-top: 10px; }
	.p-t-15 { padding-top: 15px; }
	.p-t-20 { padding-top: 20px; }
	.p-t-25 { padding-top: 25px; }
	.p-t-30 { padding-top: 30px; }
	.p-b-5 { padding-bottom: 5px; }
	.p-b-10 { padding-bottom: 10px; }
	.p-b-15 { padding-bottom: 15px; }
	.p-b-20 { padding-bottom: 20px; }
	.p-b-25 { padding-bottom: 25px; }
	.p-b-30 { padding-bottom: 30px; }
}

.system-alerts {
	position: fixed;
	top: 30px;
	width: 100%;
	z-index: 999;
	text-align: center;

	.alert {
		background: rgba(238, 97, 5, 0.75);
		border-radius: 0;
		border: none;

		p {
			color: #fff;
		}

		button {
			color: #fff;
			opacity: 1;
			font-weight: 300;
		}
	}
}

.select-wrapper {
	position: relative;
	border: 1px solid #999;
	max-width: 280px;
	border-radius: 30px;
	overflow: hidden;

	select {
		cursor: pointer;
		padding: 6px 100px 5px 15px;
		border: none;
		border-radius: 50px;
		background: #fff;
		height: auto;
		-moz-appearance: none;
		-webkit-appearance: none;
		appearance: none;
		outline: none;
		display: block;
		font-weight: 400;
		font-size: 11px;
		width: 100%;
	}

	svg {
		position: absolute;
		fill: #000;
		height: 6px;
		min-width: 6px;
		max-width: 6px;
		right: 10px;
		top: 50%;
		transform: translateY(-50%) rotate(90deg);
	}
}

#modalIngredient {
	.modal-content {
		border-radius: 0;
		border: none;
		background-color: lighten(@gray-lighter, 4%);

		.modal-header {
			border: none;
		}

		.modal-body {
			p.heading {
				&:nth-of-type(n+2) {
					margin-top: 15px;
				}
			}

			.ingredients {
				display: flex;
				flex-wrap: wrap;

				label {
					margin-right: 5px;
					margin-top: 5px;

					.ingredient-box {
						display: block;
						text-align: center;
						border: 1px solid @color2;
						color: @color2;
						font-size: 14px;
						padding: 8px 20px;
					}
				}
				
				label > input {
					visibility: hidden;
					position: absolute;
				}
				
				label > input + .ingredient-box {
					cursor: pointer;
					border: 1px solid @color2;
				}

				label > input:checked + .ingredient-box {
					border: 1px solid @color2;
					background: @color2;
					color: #fff;
				}
			}
		}

		.modal-footer {
			border: none;
			text-align: left;
		}
	}
}

div#section-header {
	padding: 10px 0;

	nav.navbar {
		min-height: auto;
		border-radius: 0;
		border: none;
		background: none;
		margin-bottom: 0;

		ul.navbar-nav {
			li {
				padding: 45px 0 45px 20px;

				a {
					padding: 20px;
					color: #222;
					font-size: 14px;
					text-transform: uppercase;
				}

				&.account {
					padding: 45px 0;

					a {
						padding-right: 10px;

						img {
							max-height: 16px;
							opacity: 0.8;
						}
					}

					&:last-child {
						a {
							padding-left: 10px;
							padding-right: 0;
							border: none;
							position: relative;

							&:before {
								content: '';
								height: 10px;
								background: darken(@gray-lighter, 20%);
								width: 1px;
								position: absolute;
								left: 0;
								top: 25px;
							}
						}
					}
				}
			}
		}

		.navbar-brand {
			height: auto;

			img {
				height: 120px;
			}
		}
	}

	.navbar-toggle {
		padding: 0px;
		border-radius: 0;
		background: none;
		border: none;
		margin-top: 60px;
		font-size: 10px;
		text-transform: uppercase;

		.icon-bar {
			width: 27px;
			height: 2px;
			border-radius: 10px;
			background-color: #222;
		}
	}
}

div#section-main-carousel {
	height: 450px;
	background-image: url(../img/backgrounds/fotolia_119558006.jpg);
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;

	.container, .row, .col-md-12 {
		height: 100%;
	}

	.main-carousel-flexbox {
		display: flex;
		align-items: center;
		height: 100%;

		.owl-carousel-slider {
			.item {
				text-align: left;

				h1 {
					color: #fff;
					font-size: 50px;
				}

				p {
					color: #fff;
					font-size: 20px;
				}
			}
		}
	}

	&.subpage-main-carousel {
		height: 200px;
	}
}

div#section-recommended {
	overflow: hidden;
	padding: 50px 0;

	.recommended-flexbox {
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;

		.flexbox-photo {
			height: 270px;
			width: 430px;
			position: relative;

			img {
				position: absolute;
				bottom: 20px;
				left: -50px;
				z-index: 111;
			}

			.photo-border {
				border: 10px solid @color1;
				height: 100%;
				width: 100%;
				position: relative;
				z-index: 222;
			}
		}

		.flexbox-description {
			padding: 20px;
			background: @color1;
			width: 320px;
			position: relative;
			z-index: 333;
			margin-left: -10px;

			h3 {
				margin-bottom: 20px;
				text-transform: uppercase;
			}

			h4 {
				margin-bottom: 10px;
			}

			.price {
				margin-top: 20px;
				font-size: 30px;
				line-height: 20px;
				color: @color2;

				span {
					margin-right: 10px;
					text-decoration: line-through;
					color: #222;
				}

				&:after {
					content: 'PLN';
					font-size: 15px;
				}
			}
		}
	}
}

div#section-about {
	padding: 50px 0;
	background-image: url(../img/backgrounds/about.png);
	background-position: left center;
	background-size: contain;
	background-repeat: no-repeat;
}

div#section-cta {
	overflow: hidden;
	padding-top: 50px;
	position: relative;

	nav.navbar-menu {
		ul {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;

			li {
				padding: 0 20px;

				a {
					font-size: 20px;
					text-decoration: none;
					color: #222;
					display: flex;
					align-items: center;
					position: relative;
					transition: all 0.3s;

					&:before {
						content: '';
						height: 4px;
						width: 4px;
						border-radius: 50%;
						background: #222;
						position: relative;
						top: -3px;
						margin-right: 5px;
						transition: all 0.3s;
					}

					&:hover {
						color: @color2;

						&:before {
							background: @color2;
						}
					}
				}

				&:first-child {
					padding-left: 0;
				}

				&:last-child {
					padding-right: 0;
				}
			}
		}
	}

	.cta-image-absolute {
		position: absolute;
		left: 0;
		top: 0;
		height: 100%;
		display: flex;
		align-items: flex-end;
	}

	.cta-flexbox {
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: space-between;

		.flexbox-right {
			display: flex;
			flex-direction: column;

			.btn {
				align-self: center;
				margin-top: 20px;
			}
		}

		.flexbox-left {
			min-width: 500px;
			max-width: 500px;
		}
	}
}

div#menu-section-content {
	nav.navbar-menu {
		margin-top: 20px;

		ul {
			display: flex;
			flex-wrap: wrap;

			li {
				margin-right: 5px;
				margin-top: 10px;

				&.link-actived {
					a {
						background: @color2;
						border-color: @color2;
						color: #fff;
					}
				}
			}
		}
	}

	.section-heading {
		margin-top: 30px;
		margin-bottom: 10px;

		&.m-b-0 {
			margin-bottom: 0;
		}
	}

	.menu-products-wrapper {
		display: flex;
		justify-content: space-between;

		.menu-products {
			width: 72%;

			.product-service {
				width: 100%;
				margin-top: 30px;
				border: 1px solid @color1;
				padding: 20px;
				transition: border 0.3s;
				cursor: pointer;
				display: flex;
				position: relative;

				.service-image {
					min-width: 160px;
					height: 160px;
					overflow: hidden;
					position: relative;
					transition: all 0.4s;

					img {
						height: 100%;
						width: auto;
						position: absolute;
						top: 50%;
						left: 50%;
						transform: translate(-50%, -50%);
					}
				}

				.service-description {
					margin-left: 20px;
					display: flex;
					flex-direction: column;
					justify-content: space-between;
					align-items: flex-start;

					h4 {
						margin-bottom: 10px;
						font-size: 20px;
					}
				}

				.price {
					margin: 20px 0;
					font-size: 30px;
					line-height: 20px;
					color: @color2;

					span {
						margin-right: 10px;
						text-decoration: line-through;
						color: #222;
					}

					&:after {
						content: 'PLN';
						font-size: 15px;
					}
				}

				&:nth-child(2n+2), &:last-child {
					margin-right: 0;
				}

				&:hover {
					border-color: @color2;
				}
			}
		}
		
		.menu-sidebar {
			margin-top: 30px;
			position: relative;
			width: 25%;

			.sidebar-inner {
				border: 1px solid @color1;
				padding: 20px;
				position: sticky;
				top: 30px;

				.heading {
					font-weight: 200;
					margin-bottom: 10px;
					font-size: 20px;
				}

				ul {
					li {
						display: flex;
						justify-content: space-between;

						p {
							&:nth-child(1) {
								margin-right: 10px;
							}

							&:nth-child(2) {
								white-space: nowrap;
							}
						}
					}
				}

				.summary {
					margin-top: 20px;
					padding-top: 20px;
					border-top: 1px solid @color1;
					display: flex;
					justify-content: space-between;

					p {
						font-size: 20px;
					}
				}

				.btn {
					margin-top: 20px;
					width: 100%;
					background: @color2;
					border: none;
					color: #fff;

					&:hover {
						background: darken(@color2, 5%);
					}
				}
			}
		}
	}

	.menu-info {
		margin-top: 50px;
		padding: 20px;
		background: @color1;
	}
}

div#susharnia-section-content {
	margin-top: 50px;

	.content-sushi-idea {	
		margin-top: 70px;
		background-image: url(../img/pictures/sushi-idea-v2.jpg);
		background-position: right center;
		background-size: 40%;
		background-repeat: no-repeat;
	}

	.content-sushi-knife {	
		margin-top: 70px;
		background-image: url(../img/pictures/sushi-knife.png);
		background-position: left center;
		background-size: 30%;
		background-repeat: no-repeat;
	}
}

div#kontakt-section-content {
	padding-top: 50px;
	position: relative;

	.map-wrapper {
		display: flex;
		align-items: center;
		height: 100%;
		position: absolute;
		top: 25px;
		left: 0px;
		z-index: 111;
		
		#map {
			align-self: center;
			height: 270px;
			width: 500px;
		}
	}

	.content-flexbox {
		display: flex;
		align-items: center;
		justify-content: flex-end;

		.flexbox-left {
			margin: 0 50px;

			img {
				max-height: 35px;
			}

			li:first-child {
				margin-bottom: 15px;
			}
		}

		.flexbox-center {
			display: flex;
			flex-direction: column;

			p {
				color: darken(@color1, 30%);
				font-size: 20px;

				&:nth-child(2) {
					margin: 20px 0;

					small {
						font-size: 15px;
					}
				}
			}

			a {
				font-size: 20px;
				color: @color2;
				text-decoration: none;

				img {
					max-height: 35px;
				}
			}
		}

		.flexbox-right {
			.form-service {
				label {
					color: darken(@color1, 30%);
					font-weight: 200;
					font-size: 15px;
				}

				input, textarea {
					border-radius: 0;
					border: none;
					box-shadow: 3px 3px 3px 0 rgba(0, 0, 0, 0.1);
					width: 250px;
					background: @gray-lighter;
					font-size: 15px;
					color: @color2;
				}

				input {
					height: 40px;
				}

				.btn {
					margin-top: 10px;
				}
			}
		}
	}
}

div#section-footer {
	padding: 50px 0;
	position: relative;

	.footer-border {
		height: 1px;
		background: @color1;
		margin-bottom: 50px;
	}

	.section-heading {
		p {
			color: #222;
		}
	}

	ul {
		margin-top: 10px;

		li {
			padding-bottom: 10px;

			a {
				color: darken(@color1, 30%);
				font-size: 15px;
				text-decoration: none;
			}

			&:last-child {
				padding-bottom: 0;
			}
		}
	}

	.footer-contact {
		p {
			color: darken(@color1, 30%);
			font-size: 20px;
		}

		ul {
			li {
				a {
					font-size: 20px;
					color: @color2;

					img {
						max-height: 35px;
					}
				}
			}

			&.list-social {
				li {
					padding-bottom: 0;
				}
			}
		}
	}

	.container {
		position: relative;

		p.credits {
			position: absolute;
			bottom: -40px;
			text-align: center;
			color: darken(@color1, 15%);
			font-size: 14px;

			a {
				text-decoration: none;
				color: @color2;
			}
		}
	}
}

@media (max-width: @screen-md-max) {
	div#section-about {
		background-size: 32%;
	}

	div#section-cta {
		.cta-flexbox {
			flex-direction: column;

			.flexbox-left {
				margin-top: 30px;
			}
		}
	}

	div#kontakt-section-content {
		padding-top: 0;

		.map-wrapper {
			height: 100%;
			top: auto;
			left: auto;
			position: relative;
			
			#map {
				width: 100%;
			}
		}

		.content-flexbox {
			margin-top: 30px;
			display: block;

			.flexbox-left {
				margin: 20px 0;
				display: flex;
				justify-content: center;

				li:first-child {
					margin-bottom: 0px;
					margin-right: 15px;
				}
			}

			.flexbox-center {
				flex-direction: row;
				justify-content: space-between;
				align-items: center;

				p {
					&:nth-child(2) {
						margin: 0;
					}
				}
			}

			.flexbox-right {
				text-align: center;

				form {
					input, textarea {
						margin: 0 auto;
					}
				}
			}
		}
	}
}

@media (max-width: @screen-sm-max) {
	.sm-m-t-30 {
		margin-top: 30px;
	}

	div#section-about {
		background-size: 32%;
		padding: 0;
	}

	div#section-cta {
		.cta-image-absolute {
			display: none;
		}

		.cta-flexbox {
			.flexbox-left {
				.flexbox-description {
					width: 260px;
				}

				.flexbox-photo {
					height: 270px;
					width: 370px;
					border: 10px solid @color1;
					background: url(../img/pictures/fotolia_115708491.jpg) center;
					background-size: cover;
				}
			}
		}
	}

	div#menu-section-content {
		.menu-products-wrapper {
			flex-direction: column;

			.menu-products {
				width: 100%;
			}
			
			.menu-sidebar {
				width: 100%;
			}
		}
	}

	div#susharnia-section-content {
		text-align: center;

		.content-sushi-idea {	
			background: none;

			.sushi-idea-image {
				margin: 30px 0;
			}
		}

		.content-sushi-knife {	
			margin-top: 70px;
			background: none;

			.sushi-knife-image {
				width: 60%;
				margin-bottom: 30px;
			}
		}
	}

	div#section-footer {
		text-align: center;
	}
}

@media (max-width: @screen-xs-max) {
	div#section-header {
		padding-bottom: 0;

		.navbar-collapse {
			box-shadow: none;
			border: none;
		}

		nav.navbar {
			ul.navbar-nav {
				margin: 0px;

				li {
					padding: 0;

					a {
						text-align: right;
						padding: 10px 0px;
					}

					&.account {
						padding: 0;

						a {
							padding: 10px 0;
						}
					}

					&:first-child {
						a {
							padding-top: 0;
						}
					}
				}
			}
		}
	}

	div#section-about {
		background: none;
		padding: 0;
		text-align: center;
	}

	div#section-cta {
		.cta-flexbox {
			.flexbox-left {
				flex-direction: column;
				margin-top: 0;

				.flexbox-description {
					width: 100%;
				}

				.flexbox-photo {
					width: 100%;
				}
			}
		}
	}

	div#menu-section-content {
		.menu-products-wrapper {
			.menu-products {
				.product-service {
					flex-direction: column;
					align-items: center;

					.service-image {
						min-width: 100%;
					}

					.service-description {
						margin-left: 0;
						margin-top: 20px;
						text-align: center;
						align-items: center;
					}
				}
			}
		}
	}

	div#kontakt-section-content {
		padding-top: 0;

		.content-flexbox {
			.flexbox-center {
				text-align: center;
				flex-direction: column;

				p {
					&:nth-child(2) {
						margin: 20px 0;
					}
				}
			}

			.flexbox-right {
				text-align: center;

				form {
					input, textarea {
						margin: 0 auto;
						max-width: 350px;
						width: 100%;
					}
				}
			}
		}
	}
}

@media (max-width: 600px) {
	div#section-main-carousel {
		height: 300px;

		.main-carousel-flexbox {
			.owl-carousel-slider {
				.item {
					h1 {
						font-size: 32px;
					}

					p {
						font-size: 16px;
					}
				}
			}
		}

		&.subpage-main-carousel {
			height: 200px;
		}
	}

	div#section-recommended {
		.recommended-flexbox {
			flex-direction: column;

			.flexbox-photo {
				width: 100%;

				img {
					max-width: 100%;
				}
			}

			.flexbox-description {
				width: auto;
				margin-left: 0;
			}
		}
	}
}